import React, { useEffect, useState, ReactNode } from 'react';
import {
  createStyles,
  AppShell,
  Header,
  Anchor,
  Text,
  Image,
  TextInput,
  Button,
  MediaQuery,
  Burger,
  Avatar,
  UnstyledButton,
  Group,
  Menu,
  Modal,
  Container,
  rem,
  Title,
  FocusTrap,
  Paper,
  FileInput,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import axios, { AxiosError } from 'axios';
import {
  IconX,
  IconLogout,
  IconEdit,
  IconSwitchHorizontal,
  IconMail,
  IconArticle,
  IconUsersGroup,
  IconUser,
} from '@tabler/icons-react';
import Sidebar from 'components/organisms/Sidebar';
import { useAuthContext } from 'contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from 'assets/svg/Logo/logo_white.svg';
import settingIcon from 'assets/svg/Setting.svg';
import notificationIcon from 'assets/svg/Notification.svg';
import userIcon from 'assets/svg/User Icon.svg';
import { MainTemplateWrapper } from './styles';
import { ArrowDown } from 'assets/Icons/ArrowDown';
import Footer from 'components/shared/Footer';

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor: theme.colors.navbar[0],
    borderBottom: 0,
    color: '#FFF',
    border: '1px solid #EAECF0',
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  user: {
    color: '#FFF',
    // padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  userActive: {
    backgroundColor: 'transparent',
  },
  menuItem: {
    gap: '10px !important',
  },
  radiusBorder: {
    borderRadius: '4px',
  },
  logoWrapper: {
    width: '140px !important',
  },
}));

interface Props {
  children: ReactNode;
}

const MainTemplate = ({ children }: Props) => {
  const { classes, cx } = useStyles();
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [adminMenuOpened, setAdminMenuOpened] = useState(false);
  const { userName, userImage, userType, showAdmin, userInbox, setUserName, setUserImage } =
    useAuthContext();
  const [cpwOpened, { open, close }] = useDisclosure(false);
  const [uprOpened, { open: openEdit, close: closeEdit }] = useDisclosure(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [name, setName] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const [navOpened, setNavOpened] = useState(false);
  const isLargeScreen = useMediaQuery('(min-width: 768px)');
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;

  const keepSessionAlive = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/keep-alive`);
    } catch (error) {
      console.error('Error sending keep-alive request:', error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      keepSessionAlive();
    }, 300000); // 300000 ms = 5 minutes

    return () => clearInterval(interval);
  }, []);

  const handleLogout = async () => {
    try {
      // Make a request to the logout endpoint
      await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/logout`, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      window.location.href = '/login'; // redirect to login page as an example
    } catch (error) {
      console.error('Error during logout', error);
    }
  };
  const updateProfile = async (e: React.FormEvent) => {
    e.preventDefault();
    if (name || image) {
      console.log('name:', name);
      const formData = new FormData();
      formData.append('name', name);
      if (image) {
        formData.append('image', image);
      }
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_SERVER_DOMAIN}/users/edit-profile`,
          formData,
          {
            headers: {
              // 'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          },
        );
        setName('');
        setImage(null);
        notifications.show({ title: 'Update Profile', message: response.data.message });
        setUserImage(response.data.user.image);
        setUserName(response.data.user.name);
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          const message = error.response?.data.error || error.message || 'An error occurred';

          notifications.show({
            title: 'Error updating profile',
            message: message,
            color: 'red',
            icon: <IconX />,
          });
        } else {
          // handle other types of errors or rethrow them
          console.error('An unknown error occurred:', error);
        }
      }
    }
    closeEdit();
  };
  const changePassword = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_DOMAIN}/auth/change-password`,
        {
          oldPassword,
          newPassword,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        },
      );
      setOldPassword('');
      setNewPassword('');
      notifications.show({ title: 'Change Password', message: response.data.message });
      close();
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const message = error.response?.data.error || error.message || 'An error occurred';

        notifications.show({
          title: 'Error changing password',
          message: message,
          color: 'red',
          icon: <IconX />,
        });
      } else {
        // handle other types of errors or rethrow them
        console.error('An unknown error occurred:', error);
      }
    }
  };
  const blackPages = [
    '/compliance-manual-acknowledgement/new',
    '/initial-compliance-attestation/new',
    '/annual-fitness-propriety-assessment/new',
    '/annual-compliance-attestation/new',
    '/fit-proper-declaration/new',
    '/gifts-and-entertainment-authorisation/new',
    '/personal-account-dealing-authorisation/new',
    '/outside-business-interest-authorisation/new',
    '/conflict-of-interest/new',
    '/pep-identification/new',
    '/suspicious-transaction-notification/new',
    '/breach-notification/new',
    '/complaints/new',
  ];
  const bgColor = blackPages.includes(currentUrl) ? '#00000' : '#F0F7FF';

  return (
    <>
      <AppShell
        style={{ paddingBottom: '50px' }}
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        bg={bgColor}
        navbar={
          isLargeScreen || navOpened ? (
            <div onClick={() => setNavOpened((o) => !o)}>
              <Sidebar />
            </div>
          ) : undefined
        }
        header={
          <Header height={{ base: 50, md: 70 }} p="md" className={classes.header}>
            <div className={classes.headerInner}>
              <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                <Burger
                  opened={navOpened}
                  onClick={() => setNavOpened((o) => !o)}
                  size="sm"
                  color="#004BAD"
                  mr="xl"
                />
              </MediaQuery>

              <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                <Group position="left">
                  <Anchor href="/">
                    <Image src={logo} className={classes.logoWrapper} />
                  </Anchor>
                </Group>
              </MediaQuery>

              <Group position="right" classNames={classes.menuItem}>
                {showAdmin == true && (
                  <Menu
                    width={260}
                    position="bottom-end"
                    transitionProps={{ transition: 'pop-top-right' }}
                    onClose={() => setUserMenuOpened(false)}
                    onOpen={() => setUserMenuOpened(true)}
                    withinPortal
                  >
                    <Menu.Target>
                      <UnstyledButton
                        className={cx(classes.user, {
                          [classes.userActive]: adminMenuOpened,
                        })}
                      >
                        <Group
                          spacing={2}
                          bg={'white'}
                          className={classes.radiusBorder}
                          p={'4px 12px 4px 6px '}
                        >
                          <Image src={settingIcon} width={'30px'} />

                          <Text
                            weight={500}
                            size="sm"
                            color={'#000000'}
                            sx={{ lineHeight: 1 }}
                            mr={3}
                          >
                            Admin
                          </Text>
                          <ArrowDown />
                        </Group>
                      </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        icon={<IconUser size="0.9rem" stroke={1.5} />}
                        onClick={() => navigate('/manage-users')}
                      >
                        Manage users
                      </Menu.Item>
                      <Menu.Item
                        icon={<IconUsersGroup size="0.9rem" stroke={1.5} />}
                        onClick={() => navigate('/user-roles')}
                      >
                        Manage user roles
                      </Menu.Item>
                      <Menu.Item
                        icon={<IconArticle size="0.9rem" stroke={1.5} />}
                        component="a"
                        href={`${process.env.REACT_APP_SERVER_DOMAIN}/view-log`}
                      >
                        Activity log
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                )}
                <Menu
                  width={260}
                  position="bottom-end"
                  transitionProps={{ transition: 'pop-top-right' }}
                  onClose={() => setUserMenuOpened(false)}
                  onOpen={() => setUserMenuOpened(true)}
                  withinPortal
                >
                  <Menu.Target>
                    <UnstyledButton
                      className={cx(classes.user, {
                        [classes.userActive]: userMenuOpened,
                      })}
                    >
                      <Group spacing={0}>
                        {/* <Avatar radius="xl" size={28}>
                        {userName &&
                          userName
                            .split(' ')
                            .map((name) => name[0])
                            .join('')}
                      </Avatar> */}
                        <Image src={userImage ? userImage : userIcon} width={40} radius="50%" />

                        {/* <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                        {userName}
                      </Text> */}
                        {/* <IconChevronDown size={rem(12)} stroke={1.5} /> */}
                      </Group>
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>
                    {userType && userType != 'None' && (
                      <Menu.Item
                        icon={<IconEdit size="0.9rem" stroke={1.5} />}
                        onClick={() =>
                          navigate(userType === 'Employee' ? '/edit-employee' : '/edit-client')
                        }
                      >
                        Edit Profile
                      </Menu.Item>
                    )}
                    <Menu.Item icon={<IconEdit size="0.9rem" stroke={1.5} />} onClick={openEdit}>
                      Edit Profile Details
                    </Menu.Item>
                    <Menu.Item
                      icon={<IconSwitchHorizontal size="0.9rem" stroke={1.5} />}
                      onClick={open}
                    >
                      Change password
                    </Menu.Item>
                    {userInbox && (
                      <Menu.Item
                        icon={<IconMail size="0.9rem" stroke={1.5} />}
                        onClick={() => navigate('/email-inbox')}
                      >
                        Email inbox
                      </Menu.Item>
                    )}
                    <Menu.Item
                      icon={<IconLogout size="0.9rem" stroke={1.5} />}
                      onClick={handleLogout}
                    >
                      Logout
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </div>
          </Header>
        }
      >
        <Paper px={'1rem'} pt={'1rem'} bg={'transparent'}>
          <MainTemplateWrapper>{children}</MainTemplateWrapper>
        </Paper>
        <Modal
          opened={cpwOpened}
          onClose={close}
          title="Change Password"
          centered
          padding={'1.5rem'}
        >
          <form onSubmit={changePassword}>
            <TextInput
              data-autofocus
              type="password"
              label="Old Password"
              placeholder="Enter old password"
              value={oldPassword}
              onChange={(event) => setOldPassword(event.currentTarget.value)}
              required
              style={{ marginBottom: '15px' }}
            />

            <TextInput
              type="password"
              label="New Password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(event) => setNewPassword(event.currentTarget.value)}
              required
              style={{ marginBottom: '15px' }}
            />

            <Button type="submit" color="primary" fullWidth>
              Change Password
            </Button>
          </form>
        </Modal>

        <Modal
          opened={uprOpened}
          onClose={closeEdit}
          title="Update Profile"
          centered
          padding={'1.5rem'}
        >
          <form onSubmit={updateProfile}>
            <TextInput
              data-autofocus
              type="string"
              label="Name"
              placeholder="Enter name"
              value={name}
              onChange={(event) => setName(event.currentTarget.value)}
              style={{ marginBottom: '15px' }}
            />

            <FileInput
              label="Upload Profile Picture"
              placeholder="Select an image"
              accept="image/*"
              value={image}
              onChange={setImage}
              style={{ marginBottom: '15px' }}
            />

            <Button type="submit" color="primary" fullWidth>
              Update Profile
            </Button>
          </form>
        </Modal>
      </AppShell>
      <Footer paddingLeft={'18.75rem'} paddingTop={'20px'} bg={'#f0f7ff'} />
    </>
  );
};

export default MainTemplate;
